let lastKnownScrollPosition = 0;
let ticking = false;

function updateOvniOnScroll(scrollPos) {
  const ovni = document.getElementById("ovni");
  ovni.style.setProperty("---left", `${scrollPos * 0.5}px`);
  ovni.style.setProperty("---top", `${scrollPos * 0.1}px`);
  ovni.style.setProperty("---scale", `${1 + scrollPos * 0.001}`);
}

function updateIleFlottanteOnScroll(scrollPos) {
  const ileFlottante = document.getElementById("ile_flottante");
  ileFlottante.style.setProperty("---bottom", `${scrollPos * 0.1}px`);
  ileFlottante.style.setProperty("---scale", `${1 + scrollPos * 0.0001}`);
}

function updateAvionOnScroll(scrollPos) {
  const avion = document.getElementById("avion");
  avion.style.setProperty("---left", `${scrollPos * 0.1}px`);
  avion.style.setProperty("---bottom", `${scrollPos * 0.05}px`);
}

function updateMontgolfiereOnScroll(scrollPos) {
  const montgolfiere = document.getElementById("montgolfiere");
  montgolfiere.style.setProperty("---right", `${-scrollPos * 0.01}px`);
  montgolfiere.style.setProperty("---bottom", `${scrollPos * 0.5}px`);
}

function initPlayer() {
  const playerContainer = document.getElementById("player");
  const playerLinks =
    document.querySelectorAll<HTMLLinkElement>("[data-player]");

  document
    .getElementById("player-close")
    ?.addEventListener("click", (event) => {
      event.preventDefault();
      playerContainer!.innerHTML = "";
    });

  playerLinks.forEach((playerLinkElement) => {
    playerLinkElement.addEventListener("click", (event) => {
      playerContainer!.innerHTML = "";
      event.preventDefault();
      const playerIframeContent: string =
        playerLinkElement.querySelector("[data-player-content]")?.textContent ||
        "";
      playerContainer!.innerHTML = playerIframeContent;
    });
  });
}

function init() {
  initPlayer();
  document.addEventListener("scroll", (event) => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        updateOvniOnScroll(lastKnownScrollPosition);
        updateIleFlottanteOnScroll(lastKnownScrollPosition);
        updateAvionOnScroll(lastKnownScrollPosition);
        updateMontgolfiereOnScroll(lastKnownScrollPosition);
        ticking = false;
      });

      ticking = true;
    }
  });
}

document.addEventListener("DOMContentLoaded", init);
